.home-page {
  background-color: #1a1a29;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.home-page .div {
  background-color: #1a1a29;
  height: 3669px;
  overflow: hidden;
  position: relative;
  width: 1360px;
}

.home-page .overlap {
  height: 494px;
  left: 0;
  position: absolute;
  top: 275px;
  width: 1360px;
}

.home-page .text-wrapper {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 32px;
  font-weight: 600;
  left: 136px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 13px;
}

.home-page .p {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 136px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 157px;
  width: 435px;
}

.home-page .text-wrapper-2 {
  color: var(--light-colour);
  font-family: "Poppins", Helvetica;
  font-size: 52px;
  font-weight: 600;
  left: 136px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 67px;
}

.home-page .overlap-group {
  height: 494px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1360px;
}

.home-page .ellipse {
  height: 486px; /* Výška elipsy */
  width: 486px; /* Šířka elipsy */
  position: absolute;
  top: 0; /* Pozice elipsy na stránce */
  left: 722px;
  border-radius: 50%; /* Elipsovitý tvar */
  object-fit: cover; /* Obrázek vyplní oblast */
  object-position: 0px -30px; /* Posun obrázku uvnitř elipsy nahoru a doleva */
  overflow: hidden; /* Skryje části obrázku mimo elipsu */
}

.home-page .vector {
  height: 21px;
  left: 1040px;
  position: absolute;
  top: 39px;
  width: 12px;
}

.home-page .rectangle {
  background-color: #171724;
  height: 128px;
  width: 1360px;
  position: absolute;
  top: 366px;
  left: 0;
  display: flex;
  justify-content: center; /* Horizontální zarovnání (na střed) */
  align-items: center; /* Vertikální zarovnání (na střed) */
  padding: 0 20px; /* Odsazení zleva a zprava, můžete upravit hodnotu dle potřeby */
}

.home-page .rectangle p {
  color: white; /* Barva textu */
  font-size: 24px; /* Velikost textu */
  font-family: Arial, sans-serif; /* Písmo textu */
  margin: 0; /* Odstranění defaultního odsazení kolem p tagu */
}

.home-page .projects-button {
  align-items: flex-start;
  border: 2px solid;
  border-color: var(--primary-colour);
  border-radius: 8px;
  display: inline-flex;
  gap: 5px;
  left: 328px;
  padding: 12px 34px;
  position: absolute;
  top: 268px;
}

.home-page .text-wrapper-3 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2px;
  position: relative;
  width: fit-content;
}

.home-page .vuesax-bold-eye {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.home-page .about-me-button {
  align-items: flex-start;
  background-color: var(--primary-colour);
  border-radius: 8px;
  display: inline-flex;
  gap: 6px;
  left: 136px;
  padding: 12px 25px;
  position: absolute;
  top: 268px;
}

.home-page .text-wrapper-4 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.home-page .vuesax-bold-user {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.home-page .text-wrapper-5 {
  color: var(--primary-colour);
  font-family: "Poppins", Helvetica;
  font-size: 32px;
  font-weight: 600;
  left: 136px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 823px;
}

.home-page .text-wrapper-6 {
  color: var(--primary-colour);
  font-family: "Poppins", Helvetica;
  font-size: 32px;
  font-weight: 600;
  left: 136px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2321px;
}

.home-page .text-wrapper-7 {
  color: var(--primary-colour);
  font-family: "Poppins", Helvetica;
  font-size: 32px;
  font-weight: 600;
  left: 136px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3030px;
}

.home-page .text-wrapper-8 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 136px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3094px;
}

.home-page .text-wrapper-9 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 136px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 3567px;
}

.home-page .text-wrapper-10 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 430px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 3567px;
}

.home-page .text-wrapper-11 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 635px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 3567px;
}

.home-page .text-wrapper-12 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 795px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 3567px;
}

.home-page .text-wrapper-13 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 971px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 3567px;
}

.home-page .text-wrapper-14 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 666px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3030px;
}

.home-page .text-wrapper-15 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 136px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 2385px;
  width: 1027px;
}

.home-page .overlap-2 {
  height: 237px;
  left: 129px;
  position: absolute;
  top: 879px;
  width: 1192px;
}

.home-page .overlap-3 {
  height: 237px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1039px;
}

.home-page .text-wrapper-16 {
  color: var(--primary-colour);
  font-family: "Poppins", Helvetica;
  font-size: 96px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 93px;
}

.home-page .text-wrapper-17 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 7px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 0;
  width: 1032px;
}

.home-page .text-wrapper-18 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 149px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 126px;
  width: 1043px;
}

.home-page .navbar {
  align-items: flex-start;
  background-color: #181824;
  gap: 394px;
  left: 0;
  padding: 19px 160px;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
}

.home-page .text-wrapper-19 {
  color: var(--primary-colour);
  font-family: "Poppins", Helvetica;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.home-page .navbar-2 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 562px;
}

.home-page .text-wrapper-20 {
  color: var(--primary-colour);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 10px;
  position: relative;
  width: fit-content;
  display: inline-block;
  margin-right: 12px;
}

.home-page .text-wrapper-21 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 10px;
  position: relative;
  width: fit-content;
  display: inline-block;
  margin-right: 12px;
}

.home-page .frame {
  height: 254px;
  left: 136px;
  position: absolute;
  top: 1136px;
  width: 1120px;
}

.home-page .overlap-4 {
  background-color: #bdbdbd;
  box-shadow: 0px 2px 4px #00000033;
  height: 254px;
  left: 0;
  position: absolute;
  top: 0;
  width: 256px;
}

.home-page .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 77px;
  left: 24px;
  position: absolute;
  top: 152px;
  width: 228px;
}

.home-page .text {
  align-self: stretch;
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.home-page .span {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
}

.home-page .vuesax-outline-ruler {
  height: 42px !important;
  left: 24px !important;
  position: absolute !important;
  top: 97px !important;
  width: 42px !important;
}

.home-page .overlap-5 {
  background-color: #bdbdbd;
  box-shadow: 0px 2px 4px #00000033;
  height: 254px;
  left: 273px;
  position: absolute;
  top: 0;
  width: 256px;
}

.home-page .flexcontainer-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 77px;
  left: 28px;
  position: absolute;
  top: 152px;
  width: 200px;
}

.home-page .span-wrapper {
  align-self: stretch;
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.home-page .vuesax-broken-code {
  height: 52px !important;
  left: 28px !important;
  position: absolute !important;
  top: 93px !important;
  width: 52px !important;
}

.home-page .overlap-group-2 {
  background-color: #bdbdbd;
  box-shadow: 0px 2px 4px #00000033;
  height: 254px;
  left: 546px;
  position: absolute;
  top: 0;
  width: 255px;
}

.home-page .flexcontainer-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 77px;
  left: 28px;
  position: absolute;
  top: 152px;
  width: 213px;
}

.home-page .vuesax-bold-android {
  height: 62px !important;
  left: 22px !important;
  position: absolute !important;
  top: 81px !important;
  width: 62px !important;
}

.home-page .overlap-6 {
  background-color: #bdbdbd;
  box-shadow: 0px 2px 4px #00000033;
  height: 254px;
  left: 818px;
  position: absolute;
  top: 0;
  width: 256px;
}

.home-page .flexcontainer-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 77px;
  left: 28px;
  position: absolute;
  top: 152px;
  width: 201px;
}

.home-page .python-svg {
  height: 55px;
  left: 28px;
  position: absolute;
  top: 83px;
  width: 55px;
}

.home-page .text-wrapper-22 {
  color: var(--primary-colour);
  font-family: "Poppins", Helvetica;
  font-size: 32px;
  font-weight: 600;
  left: 136px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1469px;
}

.home-page .frame-2 {
  height: 692px;
  left: 136px;
  position: absolute;
  top: 1550px;
  width: 1066px;
}

.home-page .div-2 {
  border: 3px solid;
  border-color: var(--primary-colour);
  border-radius: 16px;
  box-shadow: 0px 2px 4px #00000033;
  height: 338px;
  left: 0;
  position: absolute;
  top: 0;
  width: 341px;
}

.home-page .UI-UX-designer {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 65px;
  letter-spacing: 0;
  line-height: 44.4px;
  position: absolute;
  top: 135px;
  white-space: nowrap;
}

.home-page .vuesax-ruler-pen {
  height: 56px !important;
  left: 140px !important;
  position: absolute !important;
  top: 72px !important;
  width: 56px !important;
}

.home-page .text-wrapper-23 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 17px;
  font-weight: 500;
  left: 20px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  top: 195px;
  width: 296px;
}

.home-page .overlap-7 {
  border: 3px solid;
  border-color: var(--light-colour);
  border-radius: 16px;
  box-shadow: 0px 2px 4px #00000033;
  height: 338px;
  left: 363px;
  position: absolute;
  top: 0;
  width: 341px;
}

.home-page .text-wrapper-24 {
  color: var(--primary-colour);
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 67px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 139px;
}

.home-page .vuesax-code {
  height: 57px !important;
  left: 139px !important;
  position: absolute !important;
  top: 73px !important;
  width: 57px !important;
}

.home-page .text-wrapper-25 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 17px;
  font-weight: 500;
  left: 19px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-align: center;
  top: 192px;
  width: 296px;
}

.home-page .overlap-8 {
  border: 3px solid;
  border-color: var(--primary-colour);
  border-radius: 16px;
  box-shadow: 0px 2px 4px #00000033;
  height: 338px;
  left: 727px;
  position: absolute;
  top: 0;
  width: 339px;
}

.home-page .text-wrapper-26 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 72px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 139px;
}

.home-page .vuesax-android {
  height: 70px !important;
  left: 132px !important;
  position: absolute !important;
  top: 60px !important;
  width: 70px !important;
}

.home-page .text-wrapper-27 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 17px;
  font-weight: 500;
  left: 16px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-align: center;
  top: 192px;
  width: 296px;
}

.home-page .overlap-9 {
  border-radius: 16px;
  height: 338px;
  left: 0;
  position: absolute;
  top: 354px;
  width: 341px;
}

.home-page .text-wrapper-28 {
  color: var(--primary-colour);
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 14px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 134px;
}

.home-page .vuesax-git-repo {
  height: 62px;
  left: 136px;
  position: absolute;
  top: 61px;
  width: 62px;
}

.home-page .rectangle-2 {
  border: 3px solid;
  border-color: var(--light-colour);
  border-radius: 16px;
  box-shadow: 0px 2px 4px #00000033;
  height: 338px;
  left: 0;
  position: absolute;
  top: 0;
  width: 341px;
}

.home-page .um-m-dob-e-pou-vat {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 17px;
  font-weight: 500;
  left: 25px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  top: 178px;
  width: 296px;
}

.home-page .overlap-10 {
  border-radius: 16px;
  height: 338px;
  left: 363px;
  position: absolute;
  top: 354px;
  width: 341px;
}

.home-page .text-wrapper-29 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 75px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 134px;
}

.home-page .vuesax-javascript {
  height: 54px !important;
  left: 147px !important;
  position: absolute !important;
  top: 70px !important;
  width: 54px !important;
}

.home-page .text-wrapper-30 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 17px;
  font-weight: 500;
  left: 23px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  top: 178px;
  width: 296px;
}

.home-page .overlap-11 {
  border-radius: 16px;
  height: 338px;
  left: 727px;
  position: absolute;
  top: 354px;
  width: 339px;
}

.home-page .text-wrapper-31 {
  color: var(--primary-colour);
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 86px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 134px;
}

.home-page .text-wrapper-32 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 17px;
  font-weight: 500;
  left: 22px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  top: 178px;
  width: 296px;
}

.home-page .rectangle-3 {
  border: 3px solid;
  border-color: var(--light-colour);
  border-radius: 16px;
  box-shadow: 0px 2px 4px #00000033;
  height: 338px;
  left: 0;
  position: absolute;
  top: 0;
  width: 339px;
}

.home-page .vuesax-slider {
  height: 48px;
  left: 146px;
  position: absolute;
  top: 72px;
  width: 48px;
}

.home-page .overlap-12 {
  background-color: #31313e;
  border-radius: 8px;
  box-shadow: 0px 4px 4px #00000040;
  height: 417px;
  left: 138px;
  position: absolute;
  top: 2486px;
  width: 343px;
}

.home-page .text-wrapper-33 {
  color: var(--primary-colour);
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 16px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 204px;
}

.home-page .text-wrapper-34 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 16px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 245px;
  width: 303px;
}

.home-page .thumbnail {
  background-image: url(../../../static/img/rectangle-16.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 173px;
  left: 16px;
  opacity: 0.5;
  position: absolute;
  top: 16px;
  width: 311px;
}

.home-page .rectangle-4 {
  background: linear-gradient(180deg, rgba(23.63, 23.63, 36.12, 0.25) 0%, rgba(117, 98, 224, 0.5) 100%);
  background-blend-mode: multiply;
  border-radius: 8px;
  height: 173px;
}

.home-page .div-wrapper {
  align-items: center;
  border: 2px solid;
  border-color: var(--primary-colour);
  border-radius: 8px;
  display: flex;
  gap: 5px;
  height: 43px;
  justify-content: center;
  left: 179px;
  padding: 12px 34px;
  position: absolute;
  top: 351px;
  width: 144px;
}

.home-page .text-wrapper-35 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -0.5px;
  margin-left: -12px;
  margin-right: -12px;
  margin-top: -4.5px;
  position: relative;
  width: fit-content;
  white-space: nowrap;
}

.home-page .about-me-button-2 {
  align-items: center;
  background-color: var(--primary-colour);
  border-radius: 8px;
  display: flex;
  gap: 6px;
  height: 43px;
  justify-content: center;
  left: 16px;
  padding: 12px 25px;
  position: absolute;
  top: 351px;
  width: 145px;
  text-decoration: none; /* Odstranění podtržení u odkazu */
  color: white; /* Barva textu */
  font-family: inherit; /* Zachování stylu fontu */
}

.home-page .about-me-button-2:hover,
.home-page .div-wrapper:hover {
  opacity: 0.9;
  cursor: pointer;
}

.home-page .text-wrapper-36 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -1.5px;
  margin-top: -3.5px;
  position: relative;
  width: fit-content;
}

.home-page .overlap-13 {
  background-color: #31313e;
  border-radius: 8px;
  box-shadow: 0px 4px 4px #00000040;
  height: 417px;
  left: 498px;
  position: absolute;
  top: 2486px;
  width: 344px;
}

.home-page .text-wrapper-37 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 17px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 245px;
  width: 303px;
}

.home-page .text-wrapper-38 {
  color: var(--primary-colour);
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 17px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 204px;
}

.home-page .rectangle-wrapper {
  background-image: url(../../../static/img/rectangle-17.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 173px;
  left: 17px;
  opacity: 0.5;
  position: absolute;
  top: 16px;
  width: 311px;
}

.home-page .projects-button-2 {
  align-items: center;
  border: 2px solid;
  border-color: var(--primary-colour);
  border-radius: 8px;
  display: flex;
  gap: 5px;
  height: 43px;
  justify-content: center;
  padding: 12px 34px;
  position: absolute;
  top: 351px;
  left: 182px;
  width: 144px;
  text-decoration: none; /* Odstranění podtržení u odkazu */
  color: var(--primary-colour); /* Barva textu */
  font-family: inherit;
}

.home-page .about-me-button-3 {
  align-items: center;
  background-color: var(--primary-colour);
  border-radius: 8px;
  display: flex;
  gap: 6px;
  height: 43px;
  justify-content: center;
  padding: 12px 25px;
  position: absolute;
  top: 351px;
  left: 19px;
  width: 145px;
  text-decoration: none; /* Odstranění podtržení u odkazu */
  color: white; /* Barva textu */
  font-family: inherit;
}
.home-page .projects-button-2:hover,
.home-page .about-me-button-3:hover {
  opacity: 0.9; /* Efekt při najetí myší */
  cursor: pointer;
}

.home-page .overlap-14 {
  background-color: #31313e;
  border-radius: 8px;
  box-shadow: 0px 4px 4px #00000040;
  height: 417px;
  left: 859px;
  position: absolute;
  top: 2486px;
  width: 343px;
}

.home-page .text-wrapper-39 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 21px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 245px;
  width: 303px;
}

.home-page .thumbnail-2 {
  background-image: url(../../../static/img/rectangle-18.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 173px;
  left: 17px;
  opacity: 0.5;
  position: absolute;
  top: 16px;
  width: 311px;
}

.home-page .projects-button-3 {
  align-items: center;
  border: 2px solid;
  border-color: var(--primary-colour);
  border-radius: 8px;
  display: flex;
  gap: 5px;
  height: 43px;
  justify-content: center;
  padding: 12px 34px;
  position: absolute;
  top: 351px;
  left: 184px;
  width: 144px;
  text-decoration: none; /* Odstranění podtržení u odkazu */
  color: var(--primary-colour); /* Barva textu */
  font-family: inherit; /* Zachování stylu fontu */
}

.home-page .about-me-button-4 {
  align-items: center;
  background-color: var(--primary-colour);
  border-radius: 8px;
  display: flex;
  gap: 6px;
  height: 43px;
  justify-content: center;
  padding: 12px 25px;
  position: absolute;
  top: 351px;
  left: 21px;
  width: 145px;
  text-decoration: none; /* Odstranění podtržení u odkazu */
  color: white; /* Barva textu */
  font-family: inherit; /* Zachování stylu fontu */
}

.home-page .projects-button-3:hover,
.home-page .about-me-button-4:hover {
  opacity: 0.9; /* Efekt při najetí myší */
  cursor: pointer; /* Ukazatel ruky při najetí */
}

.home-page .overlap-15 {
  background-size: 100% 100%; /* Plné pokrytí pozadí */
  border-radius: 8px;
  height: 62px;
  width: 472px;
  position: absolute;
  top: 3091px;
  left: 668px;
  background-color: #2C2C3D;
}

.home-page .overlap-15 .text-wrapper-40,
.home-page .overlap-15 .input-field {
  position: absolute;
  top: 50%; /* Vertikální vycentrování */
  transform: translateY(-50%);
  left: 16px; /* Vnitřní odsazení */
  width: calc(100% - 32px); /* Šířka textového pole */
  border: none;
  border-radius: 8px;
  padding: 12px;
  background: transparent; /* Pozadí průhledné kvůli obrázku */
  color: #FFFFFF; /* Bílý text */
  font-size: 14px;
}

.home-page .text-wrapper-40 {
  color: var(--light-colour);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 16px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 18px;
}

.home-page .overlap-16 {
  border-radius: 8px;
  height: 62px;
  width: 472px;
  position: absolute;
  top: 3169px;
  left: 668px;
  background-color: #2C2C3D; /* Tmavá barva pozadí */
}

.home-page .overlap-16 .text-wrapper-40,
.home-page .overlap-16 .input-field {
  position: absolute;
  top: 50%; /* Vertikální vycentrování */
  transform: translateY(-50%);
  left: 16px; /* Vnitřní odsazení */
  width: calc(100% - 32px);
  border: none;
  border-radius: 8px;
  padding: 12px;
  background-color: transparent; /* Průhledné, aby zachovalo design */
  color: #FFFFFF;
  font-size: 14px;
}

.home-page .rectangle-5 {
  background-color: var(--light-colour);
  border-radius: 8px;
  height: 62px;
  left: 0;
  opacity: 0.11;
  position: absolute;
  top: 0;
  width: 472px;
}

.home-page .overlap-17 {
  border-radius: 8px;
  height: 98px;
  width: 472px;
  position: absolute;
  top: 3247px;
  left: 668px;
  background-color: #2C2C3D; /* Tmavší barva pozadí */
}

.home-page .overlap-17 .text-wrapper-41,
.home-page .overlap-17 .textarea-field {
  position: absolute;
  top: 16px;
  left: 16px;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  border: none;
  border-radius: 8px;
  padding: 12px;
  background-color: transparent; /* Průhledné, pro zachování jednotného designu */
  color: #FFFFFF;
  font-size: 14px;
  resize: none; /* Zakázání změny velikosti */
}

.input-field:focus,
.textarea-field:focus {
  outline: 2px solid #5A5A7D; /* Zvýraznění při zaostření */
}

.text-wrapper-41 {
  display: block; /* Umožní správné zobrazení jako blokový element */
  color: #8C8C99; /* Světlá barva textu */
  font-size: 14px; /* Velikost textu */
  margin-bottom: 4px;
  padding-left: 8px; /* Odstup od levé strany */
}

.text-wrapper-41 textarea {
  width: calc(100% - 16px); /* Přizpůsobení šířky s odsazením */
  height: calc(100% - 24px); /* Výška s horním a dolním odsazením */
  margin: 4px 8px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: #3C3C4F; /* Barva textového pole */
  color: #FFFFFF; /* Barva textu uvnitř pole */
  font-size: 14px;
  resize: none; /* Zakázání změny velikosti */
}

.text-wrapper-41 textarea:focus {
  outline: 2px solid #5A5A7D; /* Zvýraznění při zaostření */
}


.home-page .rectangle-6 {
  background-color: var(--light-colour);
  border-radius: 8px;
  height: 98px;
  left: 0;
  opacity: 0.11;
  position: absolute;
  top: 0;
  width: 472px;
}

.home-page .text-wrapper-41 {
  color: var(--light-colour);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 16px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 36px;
}

.home-page .overlap-18 {
  background-color: #7562e0; /* Barva pozadí */
  border: none; /* Zrušení okraje tlačítka */
  border-radius: 8px; /* Zakulacené rohy */
  color: white; /* Barva textu */
  font-size: 16px; /* Velikost písma */
  font-weight: bold; /* Tučný text */
  text-align: center; /* Zarovnání textu na střed */
  height: 50px; /* Výška tlačítka */
  width: 174px; /* Šířka tlačítka */
  cursor: pointer; /* Změna kurzoru na pointer */
  display: flex; /* Flexbox pro zarovnání obsahu */
  align-items: center; /* Vertikální zarovnání */
  justify-content: center; /* Horizontální zarovnání */
  position: absolute; /* Absolutní pozicování */
  left: 668px; /* Pozice od levého okraje (stejné jako ve vašem kódu) */
  top: 3361px; /* Pozice od horního okraje (stejné jako ve vašem kódu) */
}
.overlap-18:hover {
  background-color: #6753c7; /* Mírně tmavší barva při hoveru */
}

.home-page .text-wrapper-42 {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 67px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
  white-space: nowrap;
  width: 41px;
}

.home-page .vuesax-bold-facebook {
  height: 32px !important;
  left: 138px !important;
  position: absolute !important;
  top: 3146px !important;
  width: 32px !important;
}

.home-page .vuesax-bold-facebook-1 {
  height: 30px !important;
  left: 138px !important;
  position: absolute !important;
  top: 3607px !important;
  width: 30px !important;
}

.home-page .vuesax-bold {
  height: 32px !important;
  left: 186px !important;
  position: absolute !important;
  top: 3146px !important;
  width: 32px !important;
}

.home-page .vuesax-bold-instagram {
  height: 30px !important;
  left: 183px !important;
  position: absolute !important;
  top: 3607px !important;
  width: 30px !important;
}

.home-page .vuesax-bold-dribbble {
  height: 32px !important;
  left: 234px !important;
  position: absolute !important;
  top: 3146px !important;
  width: 32px !important;
}

.home-page .vuesax-bold-dribbble-1 {
  height: 30px !important;
  left: 228px !important;
  position: absolute !important;
  top: 3607px !important;
  width: 30px !important;
}

.home-page .vuesax-bold-sms {
  height: 32px !important;
  left: 282px !important;
  position: absolute !important;
  top: 3146px !important;
  width: 32px !important;
}

.home-page .vuesax-bold-sms-instance {
  height: 30px !important;
  left: 273px !important;
  position: absolute !important;
  top: 3607px !important;
  width: 30px !important;
}
.menu-list, .menu-list-2 {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
}

.menu-list li {
  margin: 0;
}
.menu-list li:hover a{
  font-size: 30px; /* Increase font size on hover */
  color: #7562e0;
}
.menu-list li a {
  text-decoration: none;
  color: White; /* Dark color for the link text */
  font-size: 16px; /* Set the initial font size */
  transition: color 0.3s, font-size 0.3s; /* Smooth transition for color and font size change */
}
.text-wrapper-21 li {
  white-space: nowrap;
}
 .menu-list-2 li a{
   color: white;
 }
.input {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 7px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 0;
  width: 1032px;
}
html {
  scroll-behavior: smooth;
}
.overlap-4:hover, .overlap-5:hover, .overlap-group-2:hover, .overlap-6:hover{
  background-color: #7562e0;
}


