.home-page {
  background-color: #1a1a29;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.home-page .div {
  background-color: #1a1a29;
  width: 1360px;
  height: 3669px;
  position: relative;
  overflow: hidden;
}

.home-page .overlap {
  width: 1360px;
  height: 494px;
  position: absolute;
  top: 275px;
  left: 0;
}

.home-page .text-wrapper {
  color: var(--white);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 13px;
  left: 136px;
}

.home-page .p {
  color: var(--white);
  letter-spacing: 0;
  width: 435px;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 157px;
  left: 136px;
}

.home-page .text-wrapper-2 {
  color: var(--light-colour);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 52px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 67px;
  left: 136px;
}

.home-page .overlap-group {
  width: 1360px;
  height: 494px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .ellipse {
  object-fit: cover;
  object-position: 0px -30px;
  border-radius: 50%;
  width: 486px;
  height: 486px;
  position: absolute;
  top: 0;
  left: 722px;
  overflow: hidden;
}

.home-page .vector {
  width: 12px;
  height: 21px;
  position: absolute;
  top: 39px;
  left: 1040px;
}

.home-page .rectangle {
  background-color: #171724;
  justify-content: center;
  align-items: center;
  width: 1360px;
  height: 128px;
  padding: 0 20px;
  display: flex;
  position: absolute;
  top: 366px;
  left: 0;
}

.home-page .rectangle p {
  color: #fff;
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 24px;
}

.home-page .projects-button {
  border: 2px solid;
  border-color: var(--primary-colour);
  border-radius: 8px;
  align-items: flex-start;
  gap: 5px;
  padding: 12px 34px;
  display: inline-flex;
  position: absolute;
  top: 268px;
  left: 328px;
}

.home-page .text-wrapper-3 {
  color: var(--white);
  letter-spacing: 0;
  width: fit-content;
  margin-top: -2px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.home-page .vuesax-bold-eye {
  width: 20px !important;
  height: 20px !important;
  position: relative !important;
}

.home-page .about-me-button {
  background-color: var(--primary-colour);
  border-radius: 8px;
  align-items: flex-start;
  gap: 6px;
  padding: 12px 25px;
  display: inline-flex;
  position: absolute;
  top: 268px;
  left: 136px;
}

.home-page .text-wrapper-4 {
  color: #fff;
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.home-page .vuesax-bold-user {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.home-page .text-wrapper-5 {
  color: var(--primary-colour);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 823px;
  left: 136px;
}

.home-page .text-wrapper-6 {
  color: var(--primary-colour);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 2321px;
  left: 136px;
}

.home-page .text-wrapper-7 {
  color: var(--primary-colour);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 3030px;
  left: 136px;
}

.home-page .text-wrapper-8 {
  color: var(--white);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 3094px;
  left: 136px;
}

.home-page .text-wrapper-9 {
  color: var(--white);
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 3567px;
  left: 136px;
}

.home-page .text-wrapper-10 {
  color: var(--white);
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 3567px;
  left: 430px;
}

.home-page .text-wrapper-11 {
  color: var(--white);
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 3567px;
  left: 635px;
}

.home-page .text-wrapper-12 {
  color: var(--white);
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 3567px;
  left: 795px;
}

.home-page .text-wrapper-13 {
  color: var(--white);
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 3567px;
  left: 971px;
}

.home-page .text-wrapper-14 {
  color: var(--white);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 3030px;
  left: 666px;
}

.home-page .text-wrapper-15 {
  color: var(--white);
  letter-spacing: 0;
  width: 1027px;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  position: absolute;
  top: 2385px;
  left: 136px;
}

.home-page .overlap-2 {
  width: 1192px;
  height: 237px;
  position: absolute;
  top: 879px;
  left: 129px;
}

.home-page .overlap-3 {
  width: 1039px;
  height: 237px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .text-wrapper-16 {
  color: var(--primary-colour);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 96px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 93px;
  left: 0;
}

.home-page .text-wrapper-17 {
  color: var(--white);
  letter-spacing: 0;
  width: 1032px;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  position: absolute;
  top: 0;
  left: 7px;
}

.home-page .text-wrapper-18 {
  color: var(--white);
  letter-spacing: 0;
  width: 1043px;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 126px;
  left: 149px;
}

.home-page .navbar {
  z-index: 1000;
  background-color: #181824;
  justify-content: center;
  align-items: flex-start;
  gap: 394px;
  width: 100%;
  padding: 19px 160px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.home-page .text-wrapper-19 {
  color: var(--primary-colour);
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.home-page .navbar-2 {
  justify-content: space-between;
  align-items: center;
  width: 562px;
  display: flex;
  position: relative;
}

.home-page .text-wrapper-20 {
  color: var(--primary-colour);
  letter-spacing: 0;
  width: fit-content;
  margin-top: 10px;
  margin-right: 12px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  display: inline-block;
  position: relative;
}

.home-page .text-wrapper-21 {
  color: var(--white);
  letter-spacing: 0;
  width: fit-content;
  margin-top: 10px;
  margin-right: 12px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  display: inline-block;
  position: relative;
}

.home-page .frame {
  width: 1120px;
  height: 254px;
  position: absolute;
  top: 1136px;
  left: 136px;
}

.home-page .overlap-4 {
  background-color: #bdbdbd;
  width: 256px;
  height: 254px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 4px #0003;
}

.home-page .flexcontainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 228px;
  height: 77px;
  display: flex;
  position: absolute;
  top: 152px;
  left: 24px;
}

.home-page .text {
  color: #fff;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.home-page .span {
  color: #fff;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
}

.home-page .vuesax-outline-ruler {
  width: 42px !important;
  height: 42px !important;
  position: absolute !important;
  top: 97px !important;
  left: 24px !important;
}

.home-page .overlap-5 {
  background-color: #bdbdbd;
  width: 256px;
  height: 254px;
  position: absolute;
  top: 0;
  left: 273px;
  box-shadow: 0 2px 4px #0003;
}

.home-page .flexcontainer-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 200px;
  height: 77px;
  display: flex;
  position: absolute;
  top: 152px;
  left: 28px;
}

.home-page .span-wrapper {
  color: var(--white);
  letter-spacing: 0;
  align-self: stretch;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.home-page .vuesax-broken-code {
  width: 52px !important;
  height: 52px !important;
  position: absolute !important;
  top: 93px !important;
  left: 28px !important;
}

.home-page .overlap-group-2 {
  background-color: #bdbdbd;
  width: 255px;
  height: 254px;
  position: absolute;
  top: 0;
  left: 546px;
  box-shadow: 0 2px 4px #0003;
}

.home-page .flexcontainer-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 213px;
  height: 77px;
  display: flex;
  position: absolute;
  top: 152px;
  left: 28px;
}

.home-page .vuesax-bold-android {
  width: 62px !important;
  height: 62px !important;
  position: absolute !important;
  top: 81px !important;
  left: 22px !important;
}

.home-page .overlap-6 {
  background-color: #bdbdbd;
  width: 256px;
  height: 254px;
  position: absolute;
  top: 0;
  left: 818px;
  box-shadow: 0 2px 4px #0003;
}

.home-page .flexcontainer-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 201px;
  height: 77px;
  display: flex;
  position: absolute;
  top: 152px;
  left: 28px;
}

.home-page .python-svg {
  width: 55px;
  height: 55px;
  position: absolute;
  top: 83px;
  left: 28px;
}

.home-page .text-wrapper-22 {
  color: var(--primary-colour);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 1469px;
  left: 136px;
}

.home-page .frame-2 {
  width: 1066px;
  height: 692px;
  position: absolute;
  top: 1550px;
  left: 136px;
}

.home-page .div-2 {
  border: 3px solid;
  border-color: var(--primary-colour);
  border-radius: 16px;
  width: 341px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 4px #0003;
}

.home-page .UI-UX-designer {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 44.4px;
  position: absolute;
  top: 135px;
  left: 65px;
}

.home-page .vuesax-ruler-pen {
  width: 56px !important;
  height: 56px !important;
  position: absolute !important;
  top: 72px !important;
  left: 140px !important;
}

.home-page .text-wrapper-23 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 296px;
  font-family: Poppins, Helvetica;
  font-size: 17px;
  font-weight: 500;
  line-height: 32px;
  position: absolute;
  top: 195px;
  left: 20px;
}

.home-page .overlap-7 {
  border: 3px solid;
  border-color: var(--light-colour);
  border-radius: 16px;
  width: 341px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 363px;
  box-shadow: 0 2px 4px #0003;
}

.home-page .text-wrapper-24 {
  color: var(--primary-colour);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 139px;
  left: 67px;
}

.home-page .vuesax-code {
  width: 57px !important;
  height: 57px !important;
  position: absolute !important;
  top: 73px !important;
  left: 139px !important;
}

.home-page .text-wrapper-25 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 296px;
  font-family: Poppins, Helvetica;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  position: absolute;
  top: 192px;
  left: 19px;
}

.home-page .overlap-8 {
  border: 3px solid;
  border-color: var(--primary-colour);
  border-radius: 16px;
  width: 339px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 727px;
  box-shadow: 0 2px 4px #0003;
}

.home-page .text-wrapper-26 {
  color: var(--white);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 139px;
  left: 72px;
}

.home-page .vuesax-android {
  width: 70px !important;
  height: 70px !important;
  position: absolute !important;
  top: 60px !important;
  left: 132px !important;
}

.home-page .text-wrapper-27 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 296px;
  font-family: Poppins, Helvetica;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  position: absolute;
  top: 192px;
  left: 16px;
}

.home-page .overlap-9 {
  border-radius: 16px;
  width: 341px;
  height: 338px;
  position: absolute;
  top: 354px;
  left: 0;
}

.home-page .text-wrapper-28 {
  color: var(--primary-colour);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 134px;
  left: 14px;
}

.home-page .vuesax-git-repo {
  width: 62px;
  height: 62px;
  position: absolute;
  top: 61px;
  left: 136px;
}

.home-page .rectangle-2 {
  border: 3px solid;
  border-color: var(--light-colour);
  border-radius: 16px;
  width: 341px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 4px #0003;
}

.home-page .um-m-dob-e-pou-vat {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 296px;
  font-family: Poppins, Helvetica;
  font-size: 17px;
  font-weight: 500;
  line-height: 32px;
  position: absolute;
  top: 178px;
  left: 25px;
}

.home-page .overlap-10 {
  border-radius: 16px;
  width: 341px;
  height: 338px;
  position: absolute;
  top: 354px;
  left: 363px;
}

.home-page .text-wrapper-29 {
  color: var(--white);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 134px;
  left: 75px;
}

.home-page .vuesax-javascript {
  width: 54px !important;
  height: 54px !important;
  position: absolute !important;
  top: 70px !important;
  left: 147px !important;
}

.home-page .text-wrapper-30 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 296px;
  font-family: Poppins, Helvetica;
  font-size: 17px;
  font-weight: 500;
  line-height: 32px;
  position: absolute;
  top: 178px;
  left: 23px;
}

.home-page .overlap-11 {
  border-radius: 16px;
  width: 339px;
  height: 338px;
  position: absolute;
  top: 354px;
  left: 727px;
}

.home-page .text-wrapper-31 {
  color: var(--primary-colour);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 134px;
  left: 86px;
}

.home-page .text-wrapper-32 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 296px;
  font-family: Poppins, Helvetica;
  font-size: 17px;
  font-weight: 500;
  line-height: 32px;
  position: absolute;
  top: 178px;
  left: 22px;
}

.home-page .rectangle-3 {
  border: 3px solid;
  border-color: var(--light-colour);
  border-radius: 16px;
  width: 339px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 4px #0003;
}

.home-page .vuesax-slider {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 72px;
  left: 146px;
}

.home-page .overlap-12 {
  background-color: #31313e;
  border-radius: 8px;
  width: 343px;
  height: 417px;
  position: absolute;
  top: 2486px;
  left: 138px;
  box-shadow: 0 4px 4px #00000040;
}

.home-page .text-wrapper-33 {
  color: var(--primary-colour);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 204px;
  left: 16px;
}

.home-page .text-wrapper-34 {
  color: var(--white);
  letter-spacing: 0;
  width: 303px;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 245px;
  left: 16px;
}

.home-page .thumbnail {
  opacity: .5;
  background-image: url("rectangle-16.ff1c7946.png");
  background-position: 50%;
  background-size: cover;
  width: 311px;
  height: 173px;
  position: absolute;
  top: 16px;
  left: 16px;
}

.home-page .rectangle-4 {
  background-blend-mode: multiply;
  background: linear-gradient(#18182440 0%, #7562e080 100%);
  border-radius: 8px;
  height: 173px;
}

.home-page .div-wrapper {
  border: 2px solid;
  border-color: var(--primary-colour);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 144px;
  height: 43px;
  padding: 12px 34px;
  display: flex;
  position: absolute;
  top: 351px;
  left: 179px;
}

.home-page .text-wrapper-35 {
  color: var(--white);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin: -4.5px -12px -.5px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.home-page .about-me-button-2 {
  background-color: var(--primary-colour);
  color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 145px;
  height: 43px;
  padding: 12px 25px;
  font-family: inherit;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 351px;
  left: 16px;
}

.home-page .about-me-button-2:hover, .home-page .div-wrapper:hover {
  opacity: .9;
  cursor: pointer;
}

.home-page .text-wrapper-36 {
  color: #fff;
  letter-spacing: 0;
  width: fit-content;
  margin-top: -3.5px;
  margin-bottom: -1.5px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.home-page .overlap-13 {
  background-color: #31313e;
  border-radius: 8px;
  width: 344px;
  height: 417px;
  position: absolute;
  top: 2486px;
  left: 498px;
  box-shadow: 0 4px 4px #00000040;
}

.home-page .text-wrapper-37 {
  color: var(--white);
  letter-spacing: 0;
  width: 303px;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 245px;
  left: 17px;
}

.home-page .text-wrapper-38 {
  color: var(--primary-colour);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 204px;
  left: 17px;
}

.home-page .rectangle-wrapper {
  opacity: .5;
  background-image: url("rectangle-17.69c982a7.png");
  background-position: 50%;
  background-size: cover;
  width: 311px;
  height: 173px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.home-page .projects-button-2 {
  border: 2px solid;
  border-color: var(--primary-colour);
  width: 144px;
  height: 43px;
  color: var(--primary-colour);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 12px 34px;
  font-family: inherit;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 351px;
  left: 182px;
}

.home-page .about-me-button-3 {
  background-color: var(--primary-colour);
  color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 145px;
  height: 43px;
  padding: 12px 25px;
  font-family: inherit;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 351px;
  left: 19px;
}

.home-page .projects-button-2:hover, .home-page .about-me-button-3:hover {
  opacity: .9;
  cursor: pointer;
}

.home-page .overlap-14 {
  background-color: #31313e;
  border-radius: 8px;
  width: 343px;
  height: 417px;
  position: absolute;
  top: 2486px;
  left: 859px;
  box-shadow: 0 4px 4px #00000040;
}

.home-page .text-wrapper-39 {
  color: var(--white);
  letter-spacing: 0;
  width: 303px;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 245px;
  left: 21px;
}

.home-page .thumbnail-2 {
  opacity: .5;
  background-image: url("rectangle-18.1ed27dbf.png");
  background-position: 50%;
  background-size: cover;
  width: 311px;
  height: 173px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.home-page .projects-button-3 {
  border: 2px solid;
  border-color: var(--primary-colour);
  width: 144px;
  height: 43px;
  color: var(--primary-colour);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 12px 34px;
  font-family: inherit;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 351px;
  left: 184px;
}

.home-page .about-me-button-4 {
  background-color: var(--primary-colour);
  color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 145px;
  height: 43px;
  padding: 12px 25px;
  font-family: inherit;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 351px;
  left: 21px;
}

.home-page .projects-button-3:hover, .home-page .about-me-button-4:hover {
  opacity: .9;
  cursor: pointer;
}

.home-page .overlap-15 {
  background-color: #2c2c3d;
  background-size: 100% 100%;
  border-radius: 8px;
  width: 472px;
  height: 62px;
  position: absolute;
  top: 3091px;
  left: 668px;
}

.home-page .overlap-15 .text-wrapper-40, .home-page .overlap-15 .input-field {
  color: #fff;
  background: none;
  border: none;
  border-radius: 8px;
  width: calc(100% - 32px);
  padding: 12px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.home-page .text-wrapper-40 {
  color: var(--light-colour);
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 18px;
  left: 16px;
}

.home-page .overlap-16 {
  background-color: #2c2c3d;
  border-radius: 8px;
  width: 472px;
  height: 62px;
  position: absolute;
  top: 3169px;
  left: 668px;
}

.home-page .overlap-16 .text-wrapper-40, .home-page .overlap-16 .input-field {
  color: #fff;
  background-color: #0000;
  border: none;
  border-radius: 8px;
  width: calc(100% - 32px);
  padding: 12px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.home-page .rectangle-5 {
  background-color: var(--light-colour);
  opacity: .11;
  border-radius: 8px;
  width: 472px;
  height: 62px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .overlap-17 {
  background-color: #2c2c3d;
  border-radius: 8px;
  width: 472px;
  height: 98px;
  position: absolute;
  top: 3247px;
  left: 668px;
}

.home-page .overlap-17 .text-wrapper-41, .home-page .overlap-17 .textarea-field {
  color: #fff;
  resize: none;
  background-color: #0000;
  border: none;
  border-radius: 8px;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  padding: 12px;
  font-size: 14px;
  position: absolute;
  top: 16px;
  left: 16px;
}

.input-field:focus, .textarea-field:focus {
  outline: 2px solid #5a5a7d;
}

.text-wrapper-41 {
  color: #8c8c99;
  margin-bottom: 4px;
  padding-left: 8px;
  font-size: 14px;
  display: block;
}

.text-wrapper-41 textarea {
  color: #fff;
  resize: none;
  background-color: #3c3c4f;
  border: none;
  border-radius: 4px;
  width: calc(100% - 16px);
  height: calc(100% - 24px);
  margin: 4px 8px;
  padding: 8px;
  font-size: 14px;
}

.text-wrapper-41 textarea:focus {
  outline: 2px solid #5a5a7d;
}

.home-page .rectangle-6 {
  background-color: var(--light-colour);
  opacity: .11;
  border-radius: 8px;
  width: 472px;
  height: 98px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .text-wrapper-41 {
  color: var(--light-colour);
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 36px;
  left: 16px;
}

.home-page .overlap-18 {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #7562e0;
  border: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 174px;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  position: absolute;
  top: 3361px;
  left: 668px;
}

.overlap-18:hover {
  background-color: #6753c7;
}

.home-page .text-wrapper-42 {
  color: var(--white);
  letter-spacing: 0;
  white-space: nowrap;
  width: 41px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 67px;
}

.home-page .vuesax-bold-facebook {
  width: 32px !important;
  height: 32px !important;
  position: absolute !important;
  top: 3146px !important;
  left: 138px !important;
}

.home-page .vuesax-bold-facebook-1 {
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  top: 3607px !important;
  left: 138px !important;
}

.home-page .vuesax-bold {
  width: 32px !important;
  height: 32px !important;
  position: absolute !important;
  top: 3146px !important;
  left: 186px !important;
}

.home-page .vuesax-bold-instagram {
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  top: 3607px !important;
  left: 183px !important;
}

.home-page .vuesax-bold-dribbble {
  width: 32px !important;
  height: 32px !important;
  position: absolute !important;
  top: 3146px !important;
  left: 234px !important;
}

.home-page .vuesax-bold-dribbble-1 {
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  top: 3607px !important;
  left: 228px !important;
}

.home-page .vuesax-bold-sms {
  width: 32px !important;
  height: 32px !important;
  position: absolute !important;
  top: 3146px !important;
  left: 282px !important;
}

.home-page .vuesax-bold-sms-instance {
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  top: 3607px !important;
  left: 273px !important;
}

.menu-list, .menu-list-2 {
  justify-content: space-around;
  list-style-type: none;
  display: flex;
}

.menu-list li {
  margin: 0;
}

.menu-list li:hover a {
  color: #7562e0;
  font-size: 30px;
}

.menu-list li a {
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  transition: color .3s, font-size .3s;
}

.text-wrapper-21 li {
  white-space: nowrap;
}

.menu-list-2 li a {
  color: #fff;
}

.input {
  color: var(--white);
  letter-spacing: 0;
  width: 1032px;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  position: absolute;
  top: 0;
  left: 7px;
}

html {
  scroll-behavior: smooth;
}

.overlap-4:hover, .overlap-5:hover, .overlap-group-2:hover, .overlap-6:hover {
  background-color: #7562e0;
}
/*# sourceMappingURL=index.8ab00be4.css.map */
